<template>
  <div>
    <v-card class="pa-8" v-if="!loading">
      <header-content :pageTitle="'List Admin'" />

      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          color="primary"
          label="Cari kata kunci disini"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-none d-lg-block text-none"
          @click="$router.push('/input-admin-roles')"
          >Tambah Admin Role</v-btn
        >
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-lg-none d-md-block text-none"
          @click="$router.push('/input-admin-roles')"
          >Tambah</v-btn
        >
      </div>

      <v-data-table
        class="cursor-pointer"
        :headers="headers"
        :items="adminRoles"
        :items-per-page="10"
        :search="searchQuery"
        @click:row="toDetail"
      ></v-data-table>
    </v-card>

    <v-card class="pa-8" v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import store from "../../store/index.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  data() {
    return {
      searchQuery: "",
      loading: false,
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "Code", value: "code" },
        { text: "Name", value: "name" },
      ],
      adminRoles: [],
    };
  },

  methods: {
    toDetail(item) {
      this.$router.push("/edit-admin-roles/" + item.id);
    },
    async getAdminRoles() {
      this.loading = true;

      await store.dispatch("admin/getAdminRoles").then((r) => {
        if (r) {
          this.loading = false;
        }
      });
      this.adminRoles = store.state.admin.adminAllRoles;
    },
  },

  mounted() {
    this.getAdminRoles();
  },
};
</script>
